import * as React from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';
import Navbar from '../components/Navbar';
import Head from '../components/Head';
import heroImage from '../../assets/images/hero.svg';
import identityImage from '../../assets/images/identity.svg';
import celebrateImage from '../../assets/images/celebrate.svg';
import verificationImage from '../../assets/images/verification.svg';
import Footer from '../components/Footer';
import LearnMore from '../components/LearnMore';
import Hero from '../components/Hero';

const IndexPage: React.FunctionComponent<{}> = () => {
  return (
    <div className={styles.IndexPage}>
      <Head title='Independent Review Management | Opinous' />
      <Navbar />
      <Hero graphic={heroImage} actions={true}>
        <h1>Help customers sing your praises</h1>
        <h2>
          Opinous provides an easy-to-use independent review management platform that helps you
          collect, manage and display your customer's feedback online.
        </h2>
      </Hero>
      <section className={styles.Story}>
        <div className={styles.SectionTitle}>
          <h2>How Opinous Helps Your Business</h2>
        </div>
        <div className={styles.Part}>
          <div className={styles.Image}>
            <img src={verificationImage} />
          </div>
          <div className={styles.Body}>
            <h3>Effortlessly collect your customer's feedback</h3>
            <p>
              Opinous makes it easy to collect feedback from your customers by providing Drop-In
              integrations for your website and sales systems.
            </p>
            <br />
            <p>
              With Opinous' easy to use technology you will be able to collect more, genuine reviews
              from your customers with ease.
            </p>
          </div>
        </div>
        <div className={classNames(styles.Part, styles.Reverse)}>
          <div className={styles.ImageMobile}>
            <img src={identityImage} />
          </div>
          <div className={styles.Body}>
            <h3>Address your critics and protect your brand</h3>
            <p>
              Unhappy customers are a part of any business but online reviews are often plagued by
              malicious actors who may never have even been your customer!
            </p>
            <br />
            <p>
              Opinous allows you to publicly respond to genuine negative reviews whilst also
              providing you a comprehensive toolkit to investigate and dispute false and malicious
              reviews.
            </p>
          </div>
          <div className={styles.Image}>
            <img src={identityImage} />
          </div>
        </div>
        <div className={styles.Part}>
          <div className={styles.Image}>
            <img src={celebrateImage} />
          </div>
          <div className={styles.Body}>
            <h3>Attract new customers with verified genuine reviews</h3>
            <p>
              Reviews are an essential part of how modern consumers find and judge businesses
              online. Opinous helps you to proudly display your customers' feedback on your website
              using our Drop-In solution to drive conversion with reviews.
            </p>
            <br />
            <p>
              Using Opinous your business will instantly display a star rating on Google Search and
              can rapidly increase it's rating on sites like Google and TripAdvisor helping new
              customers find your business.
            </p>
          </div>
        </div>
      </section>
      <LearnMore />
      <Footer />
    </div>
  );
};

export default IndexPage;
